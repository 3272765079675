<script>
import { Role } from "@/router/role";

export default {
  name: "nav",

  data() {
    return {

      // + Role based authorization

      allItems: [
        //#region Dashboard
        {
          _name: "CSidebarNavItem",
          name: "Dashboard",
          to: "/dashboard",
          icon: "cil-speedometer",
        },
        {
          _name: "CSidebarNavItem",
          name: "Activities",
          to: "/activities",
          icon: "cil-fingerprint",
          authorize: [Role.SuperAdmin]
        },
        //#endregion

        //#region + CRM
        {
          _name: "CSidebarNavTitle",
          _children: ["CRM"],
          notAuthorize: [Role.TotalViewerBelgium],
        },
        {
          _name: "CSidebarNavItem",
          name: "All Users",
          to: { name: "All Users" },
          icon: "cil-group",
          authorize: [Role.SuperAdmin, Role.Admin],
        },
        {
          _name: "CSidebarNavItem",
          name: "Task List",
          to: { name: "Task List" },
          icon: "cil-bolt",
          notAuthorize: [Role.TotalViewerBelgium],
        },
        {
          _name: "CSidebarNavItem",
          name: "Email",
          to: { name: "Email" },
          icon: "cil-at",
          notAuthorize: [Role.TotalViewerBelgium],
        },
        {
          _name: "CSidebarNavItem",
          name: "Messages",
          to: { name: "Message List" },
          icon: "cil-chat-bubble",
          authorize: [Role.SuperAdmin],
        },
        //#endregion

        //#region + Contents
        {
          _name: "CSidebarNavTitle",
          _children: ["Contents"],
          authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin]
        },
        {
          // Menu Groups
          _name: "CSidebarNavItem",
          name: "Menu Groups",
          to: { name: "Menu Groups" },
          icon: "cil-object-group",
          authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin]
        },
        {
          // Menu Sjabloons
          _name: "CSidebarNavItem",
          name: "Menu Sjabloons",
          to: { name: "Menu Sjabloons" },
          icon: "cil-object-ungroup",
          authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin]
        },
        {
          // Images
          _name: "CSidebarNavItem",
          name: "Images",
          to: { name: "Images" },
          icon: "cil-image",
          authorize: [Role.SuperAdmin, Role.Admin, Role.MenuAdmin]
        },
        {
          // Vouchers
          _name: "CSidebarNavItem",
          name: "Vouchers",
          to: { name: "Vouchers" },
          icon: "cil-money",
          authorize: [Role.SuperAdmin]
        },
        //#endregion

        //#region + Restaurants
        {
          _name: "CSidebarNavTitle",
          _children: ["Restaurants"],
          authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Restaurants",
          to: { name: "Restaurants" },
          icon: "cil-restaurant",
          authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Delivery Zones",
          to: { name: "Delivery Zones" },
          icon: "cil-location-pin",
          authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Map",
          to: { name: "All Restaurants On Map" },
          icon: "cil-map",
          authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Types",
          to: { name: "Restaurant Types" },
          icon: "cil-text",
          authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Devices",
          to: { name: "Devices" },
          icon: "cil-mobile",
          authorize: [Role.SuperAdmin, Role.Admin, Role.RestAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Reviews",
          to: { name: "Reviews" },
          icon: "cil-star",
          authorize: [Role.SuperAdmin, Role.Admin]
        },
        {
          _name: 'CSidebarNavDivider',
          _class: 'my-2'
        },
        {
          _name: "CSidebarNavItem",
          name: "Order List",
          to: { name: "Order List" },
          icon: "cil-cart",
          authorize: [Role.SuperAdmin, Role.Admin, Role.OrderAdmin, Role.RestAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Invoices",
          to: { name: "Invoice List" },
          icon: "cil-spreadsheet",
          authorize: [Role.SuperAdmin, Role.Admin, Role.InvoiceAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Payments",
          to: { name: "Payment List" },
          icon: "cil-euro",
          authorize: [Role.SuperAdmin, Role.Admin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Bank Transactions",
          to: { name: "Bank Transactions" },
          icon: "cil-transfer",
          authorize: [Role.SuperAdmin, Role.Admin, Role.InvoiceAdmin]
        },
        //#endregion

        //#region + Courier
        {
          _name: "CSidebarNavTitle",
          _children: ["Menute Delivery Service"],
          authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Hubs",
          to: { name: "Hubs" },
          icon: "cil-lan",
          authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Delivery Service",
          to: { name: "DeliveryService" },
          icon: "cil-truck",
          authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Couriers",
          to: { name: "Couriers" },
          icon: "cil-group",
          authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Invoices",
          to: { name: "CourierInvoices" },
          icon: "cil-spreadsheet",
          authorize: [Role.SuperAdmin, Role.Admin, Role.CourierAdmin, Role.InvoiceAdmin]
        },
        //#endregion

        //#region + MenutePay
        {
          _name: "CSidebarNavTitle",
          _children: ["MenutePay"],
          authorize: [Role.SuperAdmin, Role.Admin, Role.InvoiceAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Companies",
          to: { name: "Companies" },
          icon: "cil-building",
          authorize: [Role.SuperAdmin, Role.Admin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Invoices",
          to: { name: "MenutePay Invoices" },
          icon: "cil-spreadsheet",
          authorize: [Role.SuperAdmin, Role.Admin, Role.InvoiceAdmin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Payments",
          to: { name: "MenutePay Payments" },
          icon: "cil-euro",
          authorize: [Role.SuperAdmin, Role.Admin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Users",
          to: { name: "MenutePay Users" },
          icon: "cil-group",
          authorize: [Role.SuperAdmin, Role.Admin]
        },
        //#endregion

        //#region + Reports
        {
          _name: "CSidebarNavTitle",
          _children: ["Reports"],
          authorize: [Role.SuperAdmin, Role.Admin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Revenue",
          to: { name: "Revenue" },
          icon: "cil-chart-line",
          authorize: [Role.SuperAdmin, Role.Admin]
        },
        //#endregion

        //#region + Settings
        {
          _name: "CSidebarNavTitle",
          _children: ["Settings"],
          authorize: [Role.SuperAdmin, Role.Admin]
        },
        {
          _name: "CSidebarNavItem",
          name: "Holidays",
          to: { name: "Holidays" },
          icon: "cil-header",
          authorize: [Role.SuperAdmin, Role.Admin]
        },
        //#endregion
      ]
    };
  },

  computed: {
    currentItems() {
      return this.allItems.filter((item) => {
        return !(item.notAuthorize && item.notAuthorize.some((role) => this.authUserRoles.includes(role))) &&
        (!item.authorize || item.authorize.some((role) => this.authUserRoles.includes(role)))
      });
    },

    sidebarItems() {
      return [
        {
          _name: "CSidebarNav",
          _children: this.currentItems,
        },
      ];
    },
  },
};
</script>
